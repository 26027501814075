import { Image } from 'components/Image'
import { rewriteAssetUrl } from 'utils/conversions/urls'
import classNames from 'classnames'

/**
 * Renders a media image component with content from Contentful.
 *
 * USAGE:
 *  Option 1 & 2: layout == 'fill' -> Image will scale down to fit containers dimensions but not more than original image dimensions
 *  Option 1: objectFit == 'cover' -> image fills container cropped to container aspect ratio
 *  Option 2: objectFit == 'contain' -> image fits fully inside container scales to first axis to hit container boundary
 *
 *  Option 3: Keep image aspect ratio and allow image to be any size to fit responsive container.
 *
 * All options:
 * 		- Style border radius on MediaImageStandard element
 * 		- Padding/Margin must be set to an additional container around the MediaImageStandard.
 *
 * @param {object} props - Component properties
 * @param {object} props.content - Content data. Required, the image content object from Contentful
 * @param {string} [props.alt] - Alternative text for the image
 * @param {number|string} [props.width] - Image width. Use with height if layout='fill' and want fixed size
 * @param {number|string} [props.height] - Image height.  Use if layout='fill' and want fixed image height
 * @param {'responsive'|'fill'|'fixed'} [props.layout='responsive'] - Image layout
 * @param {'cover'|'contain'|'fill'|'none'|'scale-down'} [props.objectFit='cover'] - Image fit. Optional (only affects layout='fill')
 * @param {'left'|'center'|'right'|'top'|'center'|'bottom'} [props.objectPosition='center'] - Image position.  Optional (only affects layout='fill')
 * @param {object} [restProps] - Other properties
 */
export function MediaImageStandard({
  content,
  alt,
  width = undefined,
  height = undefined,
  layout = 'responsive',
  objectFit = undefined,
  objectPosition = undefined,
  objectPositionMobile = undefined,
  backgroundObjectPositionClass = undefined,
  classNameContainer,
  className,
  aspectRatio = undefined,
  ...restProps
}) {
  let { url, fileName } = content?.fields?.file?.url ? content.fields.file : content || {}
  const assetUrl = rewriteAssetUrl(url)
  if (!assetUrl && !restProps?.src) return null

  const defaultHeight = content?.height || content?.fields?.file?.details?.image?.height
  const defaultWidth = content?.width || content?.fields?.file?.details?.image?.width

  // Get original image dimensions if width or height not provided as argument
  if (layout === 'responsive') {
    if (!height) {
      height = defaultHeight
    }
    if (!width) {
      width = defaultWidth
    }
  }

  /**
   * @param {string} position - css object-position value
   */
  const getObjectPositionClassName = (position) => {
    const objectPositionMap = {
      'left top': '!object-left-top',
      top: '!object-top',
      'right top': '!object-right-top',
      left: '!object-left',
      center: '!object-center',
      right: '!object-right',
      'left bottom': '!object-left-bottom',
      bottom: '!object-bottom',
      'right bottom': '!object-right-bottom',
      '80% top': '!object-[80%_top]',
    }

    return objectPositionMap[position]
  }

  // Try to get a standard tailwind object-position className
  const objectPositionClassName = objectPosition
    ? objectPositionMobile
      ? `lg:${getObjectPositionClassName(objectPosition)}`
      : getObjectPositionClassName(objectPosition)
    : null
  const objectPositionMobileClassName = objectPositionMobile
    ? getObjectPositionClassName(objectPositionMobile)
    : null

  const inlineStyles = {}

  // If no standard tailwind object class, handle custom object-position by passing to Image in
  // style object
  if (objectPosition && !objectPositionClassName) inlineStyles.objectPosition = objectPosition

  const objectFitClassName = objectFit ? `object-${objectFit}` : null

  const responsiveClasses = 'box-border min-w-full max-w-full min-h-full max-h-full border-0 p-0'
  const fillClasses = 'absolute block h-full inset-0 m-auto object-center w-full'

  const DefaultImage = () => (
    <Image
      style={inlineStyles}
      src={assetUrl}
      alt={alt || fileName}
      height={height || defaultHeight}
      width={width || defaultWidth}
      className={classNames(
        (layout === 'responsive' || layout === 'fill') && responsiveClasses,
        layout === 'fill' && fillClasses,
        objectFitClassName,
        objectPositionClassName,
        objectPositionMobileClassName,
        backgroundObjectPositionClass,
        className
      )}
      {...restProps}
    />
  )

  // Option 1: Locket aspect ratio
  if (aspectRatio) {
    const paddingBottom = `${(1 / aspectRatio) * 100}%`

    return (
      <div className="relative w-full h-0" style={{ paddingBottom }}>
        <Image
          style={inlineStyles}
          src={assetUrl}
          alt={alt || fileName}
          width={(width || defaultWidth) ?? '100'}
          height={(height || defaultHeight) ?? '100'}
          className={classNames(
            // aspectRatio locked images will use 'fill' related CSS
            fillClasses,
            // Force aspectRatio locked images to be object-fit: cover
            'object-cover',
            objectPositionClassName,
            objectPositionMobileClassName,
            className
          )}
          {...restProps}
        />
      </div>
    )
  }

  // Option 2: Fill
  if (layout === 'fill') {
    return (
      <div
        className={classNames(
          // if objectFit == 'contain', do not want cropping -> Ensure image dimensions don't
          // exceed container
          objectFit === 'contain' && 'max-h-full max-w-full',
          classNameContainer
        )}
        style={{
          width: width,
          height: height,
        }}
        data-testid="media-image-container"
      >
        <DefaultImage />
      </div>
    )
  }

  // Option 3: default
  return <DefaultImage />
}
