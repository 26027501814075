import { Typography } from 'components/Contentful'
import { Grid } from '@achieve/ascend'

import React from 'react'

const BlockQuote = ({ text }) => {
  return (
    <Grid
      className={
        'text-neutral-300 bg-blue-50 rounded-[10px] mb-8 pt-8 pb-4 px-3 lg:px-[88px] lg:py-8'
      }
    >
      {text &&
        text.map((textContent, idx) => (
          <React.Fragment key={idx}>
            <Typography
              className={'lg:mx-0 lg:my-4 font-light md:font-medium'}
              variant={'headingMd'}
              content={textContent.content[0].value}
            />
          </React.Fragment>
        ))}
    </Grid>
  )
}

export { BlockQuote }
