import { useConfig, usePageVariation } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import styles from './TrackingWrapper.module.scss'
import { useRouter } from 'next/router'
import { SectionContext } from 'providers/SectionProvider'
import { useContext } from 'react'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import {
  ACX_STICKY_FOOTER_HEADER_EXPERIMENT,
  ACX_WEB_DR_MP_VS_LX_NAME_EXPERIMENT,
  OFF_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
} from 'constants/experiments'
import { isGetStartedRoute } from 'utils/validations/isRoutes'
import { createExperimentObject, shouldWebDrMpVsLxExperiment } from 'utils/experiments'
import { replaceUrlMpLXExperiment } from 'utils/shared'
import { LayoutContext } from 'providers/LayoutProvider'

function TrackingWrapper({
  track,
  onClick,
  withNextLink,
  children,
  trackAsConversion = false,
  ...props
}) {
  const { config } = useConfig()
  const { pageVariation } = usePageVariation()
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()
  const router = useRouter()
  let url = router.asPath
  const variationContext = useContext(SectionContext)
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const enableWebDrMpVsLx = useFeatureFlag(FEATURE_TOGGLES[siteEnv]?.ACX_WEB_DR_MP_VS_LX)
  const enableStickyFooterHeader = useFeatureFlag(
    FEATURE_TOGGLES[siteEnv]?.ACX_STICKY_FOOTER_HEADER
  )
  const localClickHandler = (event, track, onClick, withNextLink, trackAsConversion) => {
    const getTrackingObject = (track) => {
      // use experiments array if it exists, otherwise create it empty one
      track.experiments = Array.isArray(track.experiments) ? track.experiments : []
      //only add experiments to the track if the href is get-started
      if (
        isGetStartedRoute(track?.href) ||
        isGetStartedRoute(track?.click_url) ||
        trackAsConversion
      ) {
        // Page section level experiment
        if (variationContext?.variation?.variant) {
          track.experiments.push(
            createExperimentObject(
              variationContext?.variation?.name,
              variationContext?.variation?.featureFlagId,
              variationContext?.variation?.variant,
              'conversion'
            )
          )
        }
        // Page level variation experiment
        if (
          pageVariation &&
          pageVariation?.id &&
          pageVariation?.id !== variationContext?.variation?.featureFlagId
        ) {
          track.experiments.push(
            createExperimentObject(
              pageVariation?.name,
              pageVariation?.id,
              pageVariation?.variation,
              'conversion'
            )
          )
        }

        // ACX_WEB_DR_MP_VS_LX experiment
        if (
          shouldWebDrMpVsLxExperiment(enableWebDrMpVsLx, router.pathname) &&
          (track?.click_url ?? track?.href ?? '').includes('achieve_dr')
        ) {
          track.experiments.push(
            createExperimentObject(
              ACX_WEB_DR_MP_VS_LX_NAME_EXPERIMENT,
              FEATURE_TOGGLES[siteEnv].ACX_WEB_DR_MP_VS_LX,
              enableWebDrMpVsLx,
              'start'
            )
          )
          if (track?.click_url)
            track.click_url = replaceUrlMpLXExperiment(track.click_url, router.pathname)
          if (track?.href) track.href = replaceUrlMpLXExperiment(track.href, router.pathname)
        }
      }

      // ACX_STICKY_FOOTER_HEADER
      if (
        enableStickyFooterHeader &&
        ![OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(enableStickyFooterHeader)
      ) {
        track.experiments.push(
          createExperimentObject(
            ACX_STICKY_FOOTER_HEADER_EXPERIMENT,
            FEATURE_TOGGLES.ACX_STICKY_FOOTER_HEADER,
            enableStickyFooterHeader,
            'conversion'
          )
        )
      }

      if (track?.click_type) {
        const trackData = {
          ...track,
          click_text: track.click_text
            ? track.click_text
            : `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${track.click_id}`,
        }

        if (track?.href) {
          if (track?.href.substring(0, 1) == '#') {
            trackData['click_url'] = url
          } else {
            trackData['click_url'] = `${track.href}`
          }
        }
        return trackData
      } else {
        return track
      }
    }
    if (withNextLink) {
      event.stopPropagation()
      event.preventDefault()
    }

    analyticsTrackEvent(handleTrackAndReactEvent(event, getTrackingObject(track), onClick))
  }

  return (
    <span
      className={styles['wrapper']}
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, onClick, withNextLink, trackAsConversion)
      }}
    >
      <> {children}</>
    </span>
  )
}

export { TrackingWrapper }
